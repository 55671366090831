<template>
  <div class="row col-xl-12 mx-0 px-0">
    <!--start::TOPFORM-->
    <Topform
      ref="client-topform"
      :managementType="managementType"
      :status="calculatedStatus"
      pageType="client"
      :name="clientInfo.name"
      :errors="clientErrors"
      :submissionStates="submissionStates"
      parentPage="/clients/view"
      :tabs="computedTabs"
      :validation="computedvalidation"
      :loadingComplete="loadingComplete"
      @clearErrors="clearErrors"
      @submit="submit"
    >
    </Topform>
    <!--end::TOPFORM-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { maxLength, required } from "vuelidate/lib/validators";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";
import PageLoader from "@/core/classes/PageLoader.js";
import Toast from "@/core/classes/Toast.js";
import AutoCompleteHelper from "@/helpers/AutoCompleteHelper";

//Vuex Imports
import {
  CREATE_CLIENT,
  UPDATE_CLIENT,
  CLEAR_CLIENT_ERRORS,
  PRELOAD_CLIENT
} from "@/core/services/store/core/client.module";

import { POPULATE_SYS_ADMINISTRATOR_LIST } from "@/core/services/store/core/user.module";

//Topform Imports
import TopformConfig from "@/core/config/topform/ManageClient.js";

export default {
  mixins: [validationMixin],

  name: "ManageClient",

  data() {
    return {
      tab: 1,
      pageLoader: new PageLoader(1),
      toast: new Toast(),
      submissionStates: {
        submitting: false
      },
      form: {
        name: "",
        key: "",
        custom_domain: "",
        active: 1,
        account_manager_id: null,
        system_country_code: "GBR",
        system_language_code: "eng",
        system_timezone_code: "UTC",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postcode: ""
      },
      allow_key_editing: true
    };
  },
  
  validations: {
    form: {
      active: {},
      name: {
        required,
        maxLength: maxLength(255)
      },
      key: {
        required,
        maxLength: maxLength(35),
        clientKey(value) { return (/^[a-z0-9_]*$/.test(value)); } // eslint-disable-line
      },
      custom_domain: {
        maxLength: maxLength(255)
      },
      account_manager_id: {},
      system_country_code: {
        required
      },
      system_language_code: {
        required
      },
      system_timezone_code: {
        required
      },
      address1: {
        maxLength: maxLength(255)
      },
      address2: {
        maxLength: maxLength(255)
      },
      city: {
        maxLength: maxLength(255)
      },
      state: {
        maxLength: maxLength(255)
      },
      postcode: {
        maxLength: maxLength(255)
      }
    }
  },

  mounted() {
    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`, route: protocol + 'admin.' + process.env.VUE_APP_API_ROOT + "/#/clients/view" },
      { title: this.calculatedBreadcrumb }
    ]);

    this.getAccountManagers();

    if (this.managementType == "update") {
      this.pageLoader.addRequiredComponent();
      this.populateClientData();
      this.pageLoader.setCompleteComponent();
    }
  },

  methods: {
    getAccountManagers: function() {
      let payload = {
        params: {
          paginate: false,
          read_only: 1,
          setup_complete: 1
        }
      };
      this.$store
        .dispatch(POPULATE_SYS_ADMINISTRATOR_LIST, payload)
        .then(() => {
          this.pageLoader.setCompleteComponent();
        });
    },
    populateClientData: function() {
      for (const [key, value] of Object.entries(this.clientInfo)) {
        if (key in this.form) {
          this.form[key] = value;
        }
      }
    },
    autofillKey() {
      if (this.allow_key_editing && this.managementType != "update") {
        this.form.key = AutoCompleteHelper.generateKey(this.form.name);
      }
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR_CLIENT_ERRORS);
    },
    submit: function() {
      this.clearErrors();

      if (this.managementType == "update") {
        this.submitUpdate();
      } else {
        this.submitPost();
      }
    },
    submitPost: function() {
      this.submissionStates.submitting = true;

      this.$store
        .dispatch(CREATE_CLIENT, this.form)
        .then(res => {
          let clientKey = this.form.key;
          let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
          this.$store.dispatch(PRELOAD_CLIENT, res.id).then(() => {
            window.location.href = protocol + clientKey + '.' + process.env.VUE_APP_ADMIN_URL_SUFFIX + "/#/client/projects";
          });
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
    submitUpdate: function() {
      let originalKey = this.clientInfo.key;
      this.submissionStates.submitting = true;

      let payload = {
        clientId: this.clientInfo.id,
        data: this.form
      };

      this.$store
        .dispatch(UPDATE_CLIENT, payload)
        .then(res => {
          let clientKey = this.form.key;
          this.$v.form.$reset();
          this.toast.notify(this, this.form.name + ' ' + `${this.$t("TOASTS.EDIT_CLIENT.MESSAGE")}`, `${this.$t("TOASTS.EDIT_CLIENT.TITLE")}`, 'success');
          this.$store.dispatch(PRELOAD_CLIENT, res.id).then(() => {
            if (originalKey == this.form.key) {
              this.$router.push({
                name: "client_projects",
                params: { key: clientKey }
              });
            } else {
              let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
              window.location.href = protocol + this.form.key + '.' + process.env.VUE_APP_ADMIN_URL_SUFFIX + "/#/client/projects";
            }
          });
        })
        .finally(() => {
          this.submissionStates.submitting = false;
        });
    },
  },

  watch: {
    calculatedName: function () {
      if (this.managementType == "post") {
        this.autofillKey();        
      }
    }
  },

  computed: {
    ...mapGetters([
      "clientErrors",
      "systemCountries",
      "systemLanguages",
      "systemTimezones",
      "userList",
      "clientInfo",
    ]),
    loadingComplete: function() {
      return this.pageLoader.getStatus();
    },
    calculatedName: function() {
      return this.form.name;
    },
    managementType: function() {
      if (this.$route.name == "edit_client") {
        return "update";
      } else {
        return "post"
      }
    },
    tab1Errors: function() {
      if (
        this.$v.form.active.$anyError ||
        this.$v.form.name.$anyError ||
        this.$v.form.account_manager_id.$anyError ||
        this.$v.form.key.$anyError ||
        this.$v.form.custom_domain.$anyError ||
        this.$v.form.system_country_code.$anyError ||
        this.$v.form.system_language_code.$anyError ||
        this.$v.form.system_timezone_code.$anyError
      ) {
        return true;
      } else {
        return false;
      }
    },
    tab2Errors: function() {
      if (
        this.$v.form.address1.$anyError ||
        this.$v.form.address2.$anyError ||
        this.$v.form.city.$anyError ||
        this.$v.form.state.$anyError ||
        this.$v.form.postcode.$anyError
      ) {
        return true;
      } else {
        return false;
      }
    },
    calculatedStatus: function() {
      if (this.clientInfo.active) {
        return "published"
      } else {
        return "unpublished"
      }
    },
    calculatedBreadcrumb: function() {
      if (this.managementType == "update") {
        return `${this.$t("NAVIGATION.BREADCRUMB.EDIT_CLIENT")}`
      } else {
        return `${this.$t("NAVIGATION.BREADCRUMB.ADD_CLIENT")}`
      }
    },
    computedvalidation: function() {
      return this.$v.form;
    },
    computedTabs: function() {
      return TopformConfig.topform(this);
    }
  }
}
</script>
