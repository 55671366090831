class ManageClient {

    //Suggests a key based on either a client or project name
    static topform(component) {
        return [
            {
              id: 1,
              name: `${component.$t("MANAGE_CLIENT.TABS.BASIC")}`,
              errors: component.tab1Errors,
              sections: [
                { 
                  rows: [
                    {
                      components: [
                        {
                          name: "InputGenericCheckbox",
                          properties: {
                            class: "col-xl-12 px-5 mb-0",
                            id: 'input-published',
                            label: `${component.$t("FORMS.INPUTS.PUBLISH")}`,
                            model: component.$v.form.active,
                            submissionStates: component.submissionStates
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  title: `${component.$t("FORMS.SECTION_HEADERS.CLIENT_INFORMATION")}`,
                  rows: [
                    {
                      components: [
                        {
                          name: "InputGeneric",
                          properties: {
                            class: "col-xl-6 px-5",
                            id: 'input-name',
                            alignLabel: "left",
                            showInline: false,
                            label: `${component.$t("FORMS.INPUTS.NAME")}`,
                            placeholder: `${component.$t("FORMS.INPUTS.NAME")}`,
                            model: component.$v.form.name,
                            submissionStates: component.submissionStates,
                            error: `${component.$t("FORMS.ERROR.NAME")}`
                          }
                        },
                        {
                          name: "InputGenericDropdown",
                          properties: {
                            class: "col-xl-6 pr-3",
                            id: 'input-account-manager',
                            label: `${component.$t("FORMS.INPUTS.ACCOUNT_MANAGER")}`,
                            placeholder: `${component.$t("FORMS.PLACEHOLDER.ACCOUNT_MANAGER")}`,
                            options: component.userList,
                            optionLabel: "name",
                            value: "id",
                            model: component.$v.form.account_manager_id,
                            errorMessage: `${component.$t("FORMS.ERROR.ACCOUNT_MANAGER")}`
                          }
                        }
                      ]
                    },
                    {
                      components: [
                        {
                          name: "InputGeneric",
                          properties: {
                            class: "col-xl-6 pr-3 key-field",
                            id: 'input-key',
                            label: `${component.$t("FORMS.INPUTS.CLIENT_KEY")}`,
                            placeholder: `${component.$t("FORMS.INPUTS.CLIENT_KEY")}`,
                            submissionStates: component.submissionStates,
                            model: component.$v.form.key,
                            showInline: false,
                            alignLabel: "left",
                            error: `${component.$t("FORMS.ERROR.CLIENT_KEY")}`,
                            disabled: true
                          }
                        },
                      ]
                    }
                  ]
                },
                {
                  title: `${component.$t("FORMS.SECTION_HEADERS.CLIENT_LOCALE")}`,
                  rows: [
                    {
                      components: [
                        {
                          name: "InputGenericDropdown",
                          properties: {
                            class: "col-xl-6 pr-3",
                            id: 'input-country-code',
                            label: `${component.$t("FORMS.INPUTS.COUNTRY")}`,
                            placeholder: `${component.$t("FORMS.PLACEHOLDER.COUNTRY")}`,
                            model: component.$v.form.system_country_code,
                            options: component.systemCountries,
                            optionLabel: "name",
                            value: "code",
                            errorMessage: `${component.$t("FORMS.ERROR.COUNTRY")}`,
                          }
                        },
                        {
                          name: "InputGenericDropdown",
                          properties: {
                            class: "col-xl-6 px-5",
                            id: 'input-language-code',
                            label: `${component.$t("FORMS.INPUTS.LANGUAGE")}`,
                            placeholder: `${component.$t("FORMS.PLACEHOLDER.LANGUAGE")}`,
                            model: component.$v.form.system_language_code,
                            options: component.systemLanguages,
                            optionLabel: "name",
                            value: "code",
                            errorMessage: `${component.$t("FORMS.ERROR.LANGUAGE")}`,
                          }
                        }
                      ]
                    },
                    {
                      components: [
                        {
                          name: "InputGenericDropdown",
                          properties: {
                            class: "col-xl-6 px-5",
                            id: 'input-timezone-code',
                            label: `${component.$t("FORMS.INPUTS.TIMEZONE")}`,
                            placeholder: `${component.$t("FORMS.PLACEHOLDER.TIMEZONE")}`,
                            model: component.$v.form.system_timezone_code,
                            options: component.systemTimezones,
                            optionLabel: "name",
                            value: "code",
                            errorMessage: `${component.$t("FORMS.ERROR.TIMEZONE")}`,
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              id: 2,
              name: `${component.$t("MANAGE_CLIENT.TABS.EXTENDED")}`,
              errors: component.tab2Errors,
              sections: [
                {
                  rows: [
                    {
                      components: [
                        {
                          name: "InputGeneric",
                          properties: {
                            class: "col-xl-6 pr-3",
                            id: 'input-address1',
                            label: `${component.$t("FORMS.INPUTS.ADDRESS1")}`,
                            placeholder: `${component.$t("FORMS.INPUTS.ADDRESS1")}`,
                            model: component.$v.form.address1,
                            showInline: false,
                            alignLabel: "left",
                            submissionStates: component.submissionStates,
                            error: `${component.$t("FORMS.ERROR.ADDRESS1")}`
                          }
                        },
                        {
                          name: "InputGeneric",
                          properties: {
                            class: "col-xl-6 pr-3",
                            id: 'input-address2',
                            label: `${component.$t("FORMS.INPUTS.ADDRESS2")}`,
                            placeholder: `${component.$t("FORMS.INPUTS.ADDRESS2")}`,
                            model: component.$v.form.address2,
                            showInline: false,
                            submissionStates: component.submissionStates,
                            alignLabel: "left",
                            error: `${component.$t("FORMS.ERROR.ADDRESS2")}`
                          }
                        }
                      ]
                    },
                    {
                      components: [
                        {
                          name: "InputGeneric",
                          properties: {
                            class: "col-xl-6 pr-3",
                            id: 'input-city',
                            label: `${component.$t("FORMS.INPUTS.CITY")}`,
                            placeholder: `${component.$t("FORMS.INPUTS.CITY")}`,
                            model: component.$v.form.city,
                            submissionStates: component.submissionStates,
                            showInline: false,
                            alignLabel: "left",
                            error: `${component.$t("FORMS.ERROR.CITY")}`
                          }
                        },
                        {
                          name: "InputGeneric",
                          properties: {
                            class: "col-xl-6 pr-3",
                            id: 'input-state',
                            label: `${component.$t("FORMS.INPUTS.STATE")}`,
                            placeholder: `${component.$t("FORMS.INPUTS.STATE")}`,
                            model: component.$v.form.state,
                            submissionStates: component.submissionStates,
                            showInline: false,
                            alignLabel: "left",
                            error: `${component.$t("FORMS.ERROR.STATE")}`
                          }
                        }
                      ]
                    },
                    {
                      components: [
                        {
                          name: "InputGeneric",
                          properties: {
                            class: "col-xl-6 pr-3",
                            id: 'input-postcode',
                            label: `${component.$t("FORMS.INPUTS.POSTCODE")}`,
                            placeholder: `${component.$t("FORMS.INPUTS.POSTCODE")}`,
                            model: component.$v.form.postcode,
                            submissionStates: component.submissionStates,
                            showInline: false,
                            alignLabel: "left",
                            error: `${component.$t("FORMS.ERROR.POSTCODE")}`
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
    }
  
  }
  
export default ManageClient;